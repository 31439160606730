import React from "react";
// components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import FaqAccordion from "../../components/faq/faq-accordion";

// Helpers
import getSortedFaqDataByCategory from "../../helpers/getSortedFaqDataByCategory";

const AccountOpeningFaq = () => {
  const breadcrumbData = [
    {
      id: 1,
      url: "/open-bank-account-online",
      title: "Open a Bank Account Online"
    },
    {
      id: 3,
      active: true,
      title: "Account Opening FAQs"
    }
  ];

  const { sortedFaqs, categoryFootnotes, schema } = getSortedFaqDataByCategory(["Account Opening"]);

  const seoData = {
    title: "Frequently Asked Questions: Opening an Account",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og: title",
        content: "Frequently Asked Questions: Opening an Account"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Find out frequently asked questions on opening a bank account at WaFd Bank. Learn about opening a bank account online, checking accounts, savings & more."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/open-bank-account-online/account-opening-faq"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ],
    schema
  };

  const faqAccordionData = {
    id: "account-opening-faq-section",
    pageTitle: "Account Opening FAQs",
    title: null,
    faqData: {
      sortedFaqs,
      categoryFootnotes
    }
  };

  return (
    <SecondaryLanding>
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <FaqAccordion {...faqAccordionData} />
    </SecondaryLanding>
  );
};

export default AccountOpeningFaq;
